* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}


.body {
    margin: 0;
    padding-top: env(safe-area-inset-top);
    background-color:#5D769A;
    font-family: Inter, sans-serif;
}


button, a, li, input {
    cursor: pointer;
}

.header {
    font-family: Inter, sans-serif;
    display: flex;
    height: 58px;
    padding: 10px;
    position: static;
    background-color: #5D769A;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    box-shadow: 0px 2.98256px 7.4564px rgba(0, 0, 0, 0.2);

}

.header--links {
    display: flex;
    margin-right: 40px;
}

.logo {
    height: 50px;
    padding-left: 10px;
}

.header--title-link {
    cursor: pointer;
}

.active-class {
    background: none!important;
    text-decoration: underline;
    text-decoration-thickness: 3px;
    text-underline-offset: 5px;
    border: none;
    padding: 0!important;
    color:white;
    font-size: 16px;
    margin-left: 50px;
}

.not-active-class {
    background: none!important;
    text-decoration: none;
    border: none;
    padding: 0!important;
    color:white;
    font-size: 16px;
    margin-left: 50px;
}

footer {
    display: flex;
    position: static;
    width: 100%;
    height: 34px;
    background-color: #5D769A;
    color: white;
    gap: 23px;
}

.footer--lauseke, .footer--tekijat {
    font-family: Inter, sans-serif;
    background: none!important;
    text-decoration: none;
    border: none;
    padding-top: 5px;
    color:white;
    font-size: 16px;

}

.footer--lauseke {
    margin-left: 8%;
}

.footer--tekijat {
    padding-left: 40px;
}

main {
    font-family: Inter, sans-serif;
    width: 100%;
    min-height: calc(100vh - 87px);
    position: relative;
    display: grid;
    grid-template-columns: 2.7fr 1fr;
    margin: 0 auto;
    /*max-width: 800px;*/
}

.main-centered {
    font-family: Inter, sans-serif;
    background-color: #F9F9F9;
    min-height: calc(100vh - 87px);
    position: relative;
    display: grid;
    margin: auto;
    width: 100%;
}


.antibiotics {
    display: flex;
    flex-direction: column;
    margin-left: auto;
    margin-right: auto;
    max-width: 600px;
    width: 100%;
}

section {
    background-color: transparent;
    margin-top: 10px;
}

section > h1 {
    font-weight: 700;
    font-size: 29px;
    line-height: 39px;
    margin-top: 40px;
    margin-bottom: 34px;
}

.antibiotic-instructions {
    position: relative;
    height: 100px;
}

.antibiotic-instructions > h2 {
    margin-top: 12px;
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    color: #5D769A;
}

.antibiotic-instructions > p {
    font-weight: 400;
    font-size: 16px;
}

.line {
    height: 0px;
    color: #5D769A;
    opacity: 0.5;
    margin-top: 2px;
    margin-bottom: 10px;
    border: 1px solid #5D769A;
}

aside {
    background-color: #FBFBFB;
    z-index: -1;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-weight: 400;
    font-size: 14px;
    max-width: 500px;
}

.aside-text {
    width: 80%;
    margin-top: 86px;
    font-size: 16px;
    margin-bottom: 120px;
}

.aside-update {
    width: 80%;
    text-align: start;
    color: black;
    font-size: 16px;
    margin-top: 4px;
}

.aside-version-update {
    width: 80%;
    text-align: start;
    margin-top: 4px;
    color: black;
    font-size: 16px;
}

.treatment-container {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    background-color: #E9E9E9;
    border-radius: 20px;
    height: 100%;
    margin-top: 23px;
}

.recipe-header {
    margin-left: 25px;
    display: flex;
    align-items: center;
}

.recipe-header-container {
    margin-left: -25px;
    align-self: flex-start;
}

.recipe-icon-container {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    width: 37px;
    height: 37px;
    text-align: center;
    color: #5D769A;
    margin-top: 5px;
}

.weight-input {
    grid-column-start: 2;
    padding-right: 200px;
}

.kg-text {
    position: absolute;
    margin-left: -70px;
    margin-top: 24px;
}

.weight-icon, .weight-icon-disabled {
    position: absolute;
    top: -29px;
    right: -70px;
}

.kg-text-disabled {
    position: absolute;
    margin-left: -70px;
    margin-top: 24px;
    color: #929292;
}

.form--input {
    height: 50px;
    width: 250px;
    border-radius: 20px;
    text-align: center;
    margin: 10px;
    -webkit-background-color: #D7E2F2;
    background-color: #D7E2F2;
    font-size: 16px;
    font-weight: bold;
    border: 1.5px solid #395174;
}

.form--input-notok {
    color: rgb(145, 5, 5);
    height: 50px;
    width: 250px;
    border-radius: 20px;
    text-align: center;
    margin: 10px;
    background-color: rgb(255, 184, 184);
    font-size: 16px;
    font-weight: bold;
    border: 1.5px solid #395174;
}

.form--input:disabled {
    -webkit-color: #929292;
    color: #929292;
    background-color: #E2E2E2;  
    border: 1.5px solid #9e9e9e; 
}

::placeholder {
    color: black;
    font-weight: bold;
}

.form--input:focus::placeholder {
    color: transparent;
}

.form--input:disabled::placeholder {
    color: #707479;
}

.form--input:hover:enabled {
    -webkit-background-color: #b5cdf1;
    background-color: #b5cdf1;
}

.diagnosis-menu-choose {
    font-weight: bold;
    z-index: 2;
    margin-left: 40px;
}

.diagnosis-menu-name {
    z-index: 2;
    letter-spacing: 0.3px;
    margin-left: 5px;
    color:#1b1b1b
}

.dropdown-btn ion-icon {
    position: absolute;
    right: 10px;
    color: black;
}

.copy-button ion-icon {
    font-size: 20px;
    display: flex;
}

.dropdown-btn {
    background: rgba(0, 0, 0, 0);
    border: none;
    font-size: 16px;
    margin-top: 5px;
    margin-left: 18px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.heart-icon {
    position: absolute;
    width: 39px;
    pointer-events: none;
    right: 196px;
}

.checkbox-container {
    grid-row-start: 2;
    grid-column-start: 1;
    height: 80px;
    display: flex;
    flex-direction: column;
    margin-left: 7px;
    margin-top: 15px;
    margin-bottom: 0px;
}

.form--checkbox input {
    height: 20px;
    width: 20px;
    margin-bottom: 10px;
    position: relative;
    vertical-align: top;
}


.disabled {
    color: #929292;
}

.form--button {
    grid-row-start: 3;
    grid-column-start: 2;
    height: 50px;
    width: 250px;
    border-radius: 20px;
    margin: 10px;
    margin-top: -35px;
    background-color: #CBDAEF;
    font-weight: bold;
    font-size: 16px;
    border: 1.5px solid #395174;
    box-shadow: 0 1.5px #7b7979;
}

.form--button:active:enabled {
    box-shadow: 0 0px #7b7979;
    transform: translateY(1.5px);
}

.form--button:hover:enabled {
    -webkit-background-color: #b5cdf1;
    background-color: #b5cdf1;
}

.form--button:disabled {
    -webkit-background-color: #E2E2E2;
    background-color: #E2E2E2;
    -webkit-color:gray;
    color: gray;
    border: 1.5px solid #9e9e9e; 
    box-shadow: 0 0px #7b7979;
}


.diagnosis-form {
    display: grid;
    margin-top: 20px;
    margin-bottom: 10px;
}

.diagnosis-menu {
    position: relative;
    display: inline-block;
    grid-column-start: 1;
    width: 250px;
    height: 50px;
    padding: 0;
    padding-top: 10px;
    padding-bottom: 15px;
    margin: 9px;
    text-indent: 23px;
    border: 1.5px solid #395174;
    border-radius: 20px;
    background-color:#D7E2F2;
    -webkit-background-color:#D7E2F2;
}

.diagnosis-menu:hover {
    border-radius: 20px 20px 0 0;
}

.dropdown-content {
    position: absolute;
    display:none;
    z-index: 1;
    width: 250px;
    padding: 0;
    margin-left: -1.5px;
    padding-bottom: 0px;
    margin-top: 10px;
    -webkit-background-color:#D7E2F2;
    background-color: #D7E2F2;
    border-bottom: 1.5px solid #395174;
    border-left: 1.5px solid #395174;
    border-right: 1.5px solid #395174;
    border-radius: 0px 0px 20px 20px;
}


.dropdown:hover .dropdown-content {
    display: block;
}


.menu--items :nth-child(odd) {
    background-color: #F3F6FA;
}

.menu--items {
    list-style-type: none;
    padding: 0;
    margin-top: 10px;
    line-height: 40px;
}

.menu--items :first-child {
    border-top: 0.5px solid;
}

.menu--items li {
    height: 40px;
}

.menu--items li:hover {
    background-color: #5D769A;
    color: white;
}

.menu--items :last-child {
    border-radius: 0px 0px 20px 20px;
}

.recipe-container {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    background-color: #E9E9E9;
    border-radius: 20px;
    height: 100%;
    margin-top: 20px;
    margin-bottom: 60px;
}

.treatment-header {
    color: black;
    margin-top: 8px;
    margin-left: 15px;
    display: flex;
    gap: 14px;
}

.treatment-header > h2 {
    align-self: center;
    font-style: normal;
    font-weight: 900;
    font-size: 23px;
    margin-top: 4px;
}

.treatment-icon-container {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 6px;
    border-radius: 50%;
    width: 37px;
    height: 37px;
    text-align: center;
    color: #5D769A;
}

.no-treatment-icon-container {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    width: 35px;
    height: 35px;
    text-align: center;
    color: #5D769A;
    margin-left: 5px;
    margin-top: 2px;
    background-color: #D6D6D6;
}

.treatment-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 8px;
    margin-top: 2px;
    padding: 8px;
    border-radius: 50%;
    background-color: #D6D6D6;
    height: 35px;
    width: 35px;
    text-align: center;
}

.treatment-choises {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    max-height: 400px;
}

.choise-container {
    height: 90%;
    width: 95%;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 6px;
    margin-top: 10px;
    margin-bottom: 10px;
}

.choise {
    width: 100%;
    border-radius: 20px 20px 20px 20px;
    border: 1.5px solid #395174;
    padding: 15px;
}

.choise-header {
    color: black;
    display: flex;
    justify-content: space-between;
    margin-right: 16px;
    align-items: center;
    letter-spacing: 0.1px;
}

.choise-inner {
    margin-top: 10px;
    height: 70px;
    display: flex;
    flex-direction: column;
    gap: 2px;
}

.treatment-extra {
    height: auto;
    width: 100%;
    display: grid;
    justify-content: space-between;
    flex-direction: row-reverse;
    padding-top: 5px;
}

.description-container {
    align-self: flex-end;
}

.max-dosage-container {
    align-self: center;
    justify-self: center;
    margin-top: -10px;
    margin-left: 5px;
    margin-right: 3%;
    width: 70%;
}

.max-dosage-text {
    color: red;
    font-size: 12px;
}

.btn-calculate {
    margin-top: 2px;
    margin-left: 5.5%;
    margin-bottom: 10px;
    width: 147px;
    height: 36px;
    border: 1.5px solid #395174;
    border-radius: 20px;
    color: #395174;
    background-color: #f3f3f3;
    font-size: 15px;
    cursor: pointer;
    box-shadow: 0 1.5px #7b7979;
    grid-row-start: 1;
    grid-column-start: 2;
}

.btn-calculate:disabled {
    color: #929292;
    border-color: #929292;
    cursor: auto;
}

.btn-calculate:hover:enabled {
    background-color: #f3f3f3;
    color: #395174;
}

.btn-elements {
    display: flex;
    align-items: center;
    margin-top: -3px;
    margin-left: 2px;
}

.func-icon-closed, .func-icon-open {
    width: 40px;
    height: 40px;
    pointer-events: none;
}

.pill-icon, .pen-icon, .weight-icon, .weight-icon-disabled{
    width: 80px;
    height: 80px;
    pointer-events: none;
}

.no-antibiotic-icon, .weight-icon-disabled {
    pointer-events: none;
}

.strepto-info {
    padding: 10px;
    padding-left: 20px;
    grid-row-start: 1;
    grid-column-start: 1;
    display: flex;
    align-items: center;
    width: 100%;
    max-width: 410px;
    height: auto;
    background-color: #F2F2F2;
    color: #304769;
    gap: 10px;
    border-radius: 0px 20px 0px 20px;
}

.strepto-info > p {
    font-weight: 400;
    align-self: center;
    justify-self: flex-end;
}

.info-description {
    font-size: 14px;
    width: 400px;
}

.help-circle {
    font-size: 25px;
    margin-left: -7px;
    margin-top: 5px;
}

.treatment-calculations {
    align-self: center;
    width: 95%;
    height: 100%;
    border: 1.5px solid #395174;
    border-radius: 20px;
    margin-bottom: 15px;
    text-align: center;
}

.calculations-container {
    margin-top: 15px;
    font-size: 20px;
    margin-bottom: 15px;
}

.recipe-container h3 {
    margin-top: 3px;
    margin-left: 23px;
    align-self: flex-start;
    font-size: 18px;
    padding: 10px 10px 5px 10px;
}

.recipe-container h4 {
    margin-top: 0;
    margin-left: 23px;
    align-self: flex-start;
    font-size: 14px;
    padding-left: 10px;
}

.recipe-text-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: white;
    border-radius: 20px;
    border: 1.5px solid #395174;
    height: 100%;
    width: 95%;
    margin-top: 10px;
    margin-bottom: 10px;
    margin-left: 2.5%;
    padding: 2px;
}

.recipe-text {
    display: flex;
    flex-direction: column;
    align-self: flex-start;
    font-family: Inter, sans-serif;
    font-size: 16px;
    padding: 12px;
    margin: 0;
    margin-left: 8px;
    margin-bottom: 20px;
    width: 90%;
}

.recipe-container-bottom {
    display: flex;
    width: 95%;
    justify-content: space-between;
    background-color: white;
    padding-bottom: 8px;
    margin-left: 0px;
}

.copy-button {
    position: absolute;
    bottom: 9%;
    right: 3%;
    background-color: #F3F3F3;
    min-width: 23%;
    width: 150px;
    border: 1.5px solid #395174;
    border-radius: 20px;
    font-family: Inter, sans-serif;
    font-size: 15px;
    color: #395174;
    box-shadow: 0 1.5px #7b7979;
    text-indent: 5px;
    margin-right: 5px;
    height: 36px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.copy--image {
    width: 18px;
    float: left;
    padding-top: 1.5px;
    margin-left: 2px;
}

.copy-button:hover {
    background-color: #E9E9E9;
}

.copy-button:active {
    background-color: #E9E9E9;
    box-shadow: 0 0px #7b7979;
    transform: translateY(1.5px);
}

.copy-text {
    font-size: 15px;
    color: #395174;
    display: flex;
    justify-content: center;
    text-indent: -2px;
    padding-left: 7%;
  }

.notification-container {
    background-color: lavender;
    display:flex;
}

.bold {
    font-weight: bold;
}

.info-container {
    font-family: Inter, sans-serif;
    padding: 80px;
    margin: auto;
    width: 70%;
    background-color: white;
    min-height: calc(100vh - 87px);
}

.info-container h2 {
    font-size: 24px;
    margin-bottom: 60px;
}

.info-links {
    display: flex;
    justify-content: space-between;
}

.info-links button {
    font-size: 18px;
    border: none;
    background-color: white;
    color: black;
}

.info-links button:active {
    color: #5D769A;
    -webkit-text-fill-color: #5D769A;
}

.info-active {
    color: #556b8b;
    -webkit-text-fill-color: #556b8b;
    text-decoration-line: underline;
    -webkit-text-decoration-line: underline !important;
    text-decoration-color: #5D769A;
    -webkit-text-decoration-color: #5D769A !important;
    text-decoration-thickness: 3px;
    text-underline-offset: 6px;
}

.info-line {
    margin-top: 4px;
    margin-bottom: 60px;
}


.info-paragraph {
    margin-bottom: 10px;
    word-spacing: 1px;
    line-height: 1.3;
}

.info-bolded-text {
    font-weight: bold;
  }

.info-references {
    margin-top: 3px;
    list-style-type: none;
    font-size: small;
    line-height: 1.4;
}


.info-container h3 {
    margin-top: 30px; 
    margin-bottom: 10px;

}

.feedback-container {
    font-family: Inter, sans-serif;
    padding-top: 80px;
    margin: auto;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.loading-text {
    color: #5D769A;
    
}

.loading {
    position: absolute;
    z-index: 2;
    width: 230px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.error {
    display: block;
    position: absolute;
    z-index: 5;
    margin-top: -77px;
    margin-left: 20px;
    color: red;
    width: 260px;
    border-radius: 20px;
    font-size: 12px;
}

.feedback-form {
    border: none;
}

#hidden {
    display: none;
}

.page-not-found {
    font-family: Inter, sans-serif;
    text-align: center;
    padding: 80px;
    margin: auto;
    width: 70%;
    background-color: white;
    min-height: calc(100vh - 87px);
}

.page-not-found h2 {
    margin-bottom: 60px;
}

.page-not-found-btn {
    background: none!important;
    text-decoration: underline;
    text-decoration-thickness: 3px;
    text-underline-offset: 5px;
    border: none;
    font-size: 16px;
}

.mobile-container {
    position: absolute;
    top: 0;
    right: 0;
    margin: 0px;
    max-width: 400px;
    z-index: 8;
}

.mobile-nav-links {
    display: none;
}

.mobile-menu-btn {
    background-color: #5D769A;
    color: white;
    border: none;
    position: absolute;
    top: 12px;
    right: 18px;
}

.mobile-nav {
    position: relative;
    width: 200px;
    max-width: 480px;
    background-color: #5D769A;
    z-index: 4;
}

.nav-link {
    color: white;
    text-decoration: none;
    display: block;
    padding: 14px 16px;
}

.mobile-nav-links :nth-child(odd) {
    background-color: #4c627e;
}

.show-menu {
    display: block;
    margin-top: 57px;  
}

.cookie {
    position: fixed;
    display: flex;
    left: 0;
    bottom: 0;
    background-color: rgb(186 201 238);
    width: 100%;
    height: 90px;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 18px;
    justify-content: center;
    align-items: center;
}

.cookie > p > a {
    color: blue;
}

.cookie-accept-btn {
    margin-right: 10px;
    height: 30px;
    width: 120px;
    min-width: 120px;
    align-self: center;
    background-color: #dee8f5;
    font-weight: bold;
    border: 1.5px solid #395174;
    font-size: 18px;
    font-family: Arial, Helvetica, sans-serif;
    border-radius: 5px;
}

.cookie > p {
    padding-left: 20px;
    padding-right: 30px;
    color: black
}

.penicillin-info {
    position: fixed;
    display: flex;
    left: 0;
    bottom: 0;
    background-color: rgb(255, 253, 164);
    width: 100%;
    max-height: 600px;
    min-height: 150px;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 100px;
    justify-content: center;
    align-items: center;
    z-index: 1;
    padding-left: 17vw;
}

.penicillin-info-spacer {
    height: 150px;
}

.penicillin-info > p {
    padding-left: 20px;
    padding-right: 5%;
    padding-top: 15px;
    padding-bottom: 15px;
    color: black;
    font-size: 18px;
}

.penicillin-info-btn a {
    display: block;
    text-decoration: none;
    line-height: 38px;
    margin-right: 10vw;
    height: 38px;
    width: 140px;
    min-width: 120px;
    color: #1f1f1f;
    text-align: center;
    font-weight: bold;
    font-size: 18px;
    background-color: #ffffff;
    border: 1.5px solid rgb(202, 202, 202);
    border-radius: 5px;
    font-family: Arial, Helvetica, sans-serif;
}

.text-container {
    font-family: Inter, sans-serif;
    padding: 80px;
    margin: auto;
    width: 70%;
    background-color: white;
    min-height: calc(100vh - 87px);
}

.text-container h2 {
    padding-bottom: 40px;
}

.penicillin-info-image {
    width: 100%;
    display: flex;
    cursor: pointer;
}

.penicillin-info-image2 {
    display: flex;
    justify-content: center;
    padding-bottom: 70px;
    cursor: pointer;
    width: 90%;
}

.penicillin-image-mobile {
    width: 100%;
    cursor: pointer;
}

.penicillin-image-mobile2 {
    cursor: pointer;
    width: 100%;
}

.penicillin-txt-header {
    font-size: 24px;
    padding-bottom: 45px;
}

.modal-close-btn {
    width: 130px;
    position: fixed;
    right: 0;
    height: 37px;
    font-size: 18px;
    font-weight: bold;
    justify-self: flex-end;
    background-color: #f3f3f3;
    border: 1.5px solid rgb(202, 202, 202);
    color:#1f1f1f;
    border-radius: 10px;
    font-family: Arial, Helvetica, sans-serif;
}    

.modal {
    position: absolute;
    top: 50vh;
    left: 50vw;
    transform: translate(-50%, -50%);
    max-width: 70%;
    max-height: 100%;
    scrollbar-width: none; 
    -ms-overflow-style: none;
    box-shadow: none;  
    z-index: 100;
}

.modal img {
    max-width: 65vw;
    height: auto;
    display: block;
    margin: 0 auto;
    -webkit-overflow-scrolling: touch;
    border: none;
}

.modal::-webkit-scrollbar {
    display: none;
}

.risk-evaluation-header {
    margin-top: 50px;
    margin-bottom: 10px;
    font-size: 20px;
}

/*Scalability to mobile screen*/
@media only screen and (max-width: 630px) {
    main {
        display: flex;
        flex-direction: column;
        align-items: center;
    } .antibiotic-instructions{
        margin-left: 15px;
        margin-right: 15px;
        height: 100%;
    } .antibiotics {
        width: 93%;
        margin-left: 3%;
        margin-right: 3%;
    } .feedback-container {
       -webkit-overflow-scrolling: auto !important;
       width: 100%;
       max-width: 100%; 
       padding: 0 20px;
       z-index: 1;
    } .feedback-form {
        width: 100%;
        max-width: 100%;
    }  aside {
        grid-column-start: 1;
        width: 100%;
        overflow-x: hidden;
        max-width: 100vw;
    } .treatment-header {
        font-size: 14px;
    }  section > h1 {
        font-size: 24px;
        margin-top: 30px;
        font-family: Arial, Helvetica, sans-serif;
        margin-left: 15px;
        color: #262C30;
    }  .strepto-info {
        max-width: 558px;
        padding-right: 10px;
        padding-left: 15px;
        justify-content: center;
        font-size: 20px;
        border-radius: 0px 0px 20px 20px;
    } .info-description {
        font-size: 15px;
        max-width: 480px;
        width: 100%;
    } .help-circle {
        margin-top: 3px;
        font-size: 25px;
    } .weight-input {
        padding-right: 0px;
    } .diagnosis-form  {
        display: flex;
        flex-direction: column;
        align-items: center;
        font-family: Arial, Helvetica, sans-serif;
    } .diagnosis-menu-choose {
        font-size: 18px; 
        font-family: Arial, Helvetica, sans-serif;  
        color: black; 
    } .diagnosis-menu {
        height: 55px;
        padding-top: 15px;
        width: 100%;
        max-width: 280px;
        font-weight: 500;
        text-indent: 30px;
        font-size: 18px;
    } .diagnosis-menu-name{
        font-size: 18px;
        margin-left: 10px;
        font-family: Arial, Helvetica, sans-serif;
        color: black;
    } .diagnosis-menu-choose section {
        max-height:fit-content;
    } .checkbox-container {
        width: 290px;
        height: auto;
        margin-right: -10px;
        margin-top: 20px;
        padding-bottom: 40px;
    } .treatment-header > h2 {
        font-weight: bolder;
        font-size: 21px;
        font-family: Arial, Helvetica, sans-serif;
        margin-left: -6px;
    } .choise-inner {
        height: fit-content;
        gap: 0px;
    }  .btn-elements > p {
        font-size: 15px;
        margin-right: 10px;
    }  .copy-button {
        font-size: 14px;
        margin-right: 4px;
    } .copy--image {
        width: 17px;
    } .info-container {
        margin: 0px;
        margin-top: 0px;
        margin-bottom: 15px;
        padding: 20px;
        font-family: Arial, Helvetica, sans-serif;
    } .main-centered {
        display: flex;
        flex-direction: column;
        align-items: center;
    } .info-links {
        align-self: flex-start;
        margin-left: 10px;
    } .info-links > * {
        font-family: Arial, Helvetica, sans-serif;
        margin-bottom: 12px;
    } .info-line {
        display: none;
    } .info-container > h2 {
        display: flex;
        align-items: center;
        flex-direction: column;
    } .info-active {
        font-weight: bold;
    } .info-links :last-child {
        margin-bottom: 70px;
    } .logo {
        padding-left: 0px;
    } .menu--items > li {
        height: 48px;
        padding-left: -50px;
    } .menu--items {
        line-height: 48px;
    } .form--input {
        height: 55px;
        width: 280px;
        margin-top: 0px;
    } .form--input-notok{
        height: 55px;
        width: 280px;
        margin-top: 0px;
    } .error {
        margin-top: -8px;
    } .kg-text-disabled {
        margin-top: 17px;
        font-size: 18px;
    } .kg-text {
        margin-top: 17px;
        font-size: 18px;
    } .dropdown-content {
        position: absolute;
        margin-left: -1.5px;
        -webkit-margin-left: -1.5px;
        width: 280px;
    } .dropdown-btn {
        margin-top: 0px;
    } .form--button {
        width: 90%;
        max-width: 280px;
        height: 55px;
        font-size: 18px;
        box-shadow:none;
        font-family: Arial, Helvetica, sans-serif;
        color: black;
        margin-top: -25px;
    } ::placeholder {
        font-size: 18px;
        margin-left: 100px;
    }  .antibiotic-instructions > h2 {
        font-family: Arial, Helvetica, sans-serif;
    } .recipe-container h3,h4 {
        font-family: Arial, Helvetica, sans-serif;
    } .recipe-container-bottom {
        padding-left: 7px;
    } .recipe-header-container {
        margin-left: -35px;
    } .treatment-icon {
        margin-left: -5px;
    } .treatment-extra{
        display: flex;
        flex-direction: column;
        align-self: flex-start;
        padding-top: 0px;
    } .pen-icon  {
        margin-left: -15px;
    } .description-container {
        align-self: flex-start;
        width: 100%;
    }  .pill-icon {
        margin-left: -10px;
    } .btn-calculate {
        align-self: flex-end;
        margin-bottom: 15px;
        margin-right: 15px;
    } .aside-text{
        margin-bottom: 70px;
    } .aside-version-update {
        margin-bottom: 30px;
    } .aside-text {
        margin-top: 40px;
    } .loading {
        align-self: center;
        top: 400px;
    } .cookie {
        height: 160px;
        flex-direction: column;
        margin-bottom: 0px;
    } .cookie > p {
        margin-bottom: 15px;
        padding-right: 10px;
    } .cookie-accept-btn {
        height: 40px;
        width: 140px;
    } .heart-icon {
        right: 225px;
    } .penicillin-info {
        font-size: 40px;
    } .penicillin-info > p {
        font-size: 17px;
    } .penicillin-info-btn {
        padding-bottom: 30px;
    } .penicillin-info-btn a {
        margin-right: 0px;
    } .text-container {
        padding: 0;
    } .text-container h2 {
        padding-top: 40px;
    }  .modal img {
        -webkit-overflow-scrolling: touch;
    } .modal {
        position: relative;
        overflow-y: auto;
        -webkit-overflow-scrolling: touch; 
        padding-bottom: 200px;
        margin-top: 58px;
        background-color: rgb(255, 255, 255);
    } .modal-close-btn {
        right: 35px;
    }
}

@media only screen and (min-width: 631px) {
      .active-class {
        margin-left: 25px;
    } .not-active-class {
        margin-left: 25px;
    } main {
        display: flex;
        flex-direction: column;
        align-items: center;
    } aside {   
        background-color: rgb(255, 255, 255);
        max-width: 700px;
    } .logo {
        padding-left: 10px;
    } .diagnosis-form {
        max-width: 100%;
        justify-content: start;
    } .weight-input {
        padding-right: 0px;
    }  .recipe-container {
        width: 600px;
    } .btn-calculate {
        margin-right: 20px;
        margin-left: 0;
    }  .aside-text {
        margin-top: 150px;
    } .aside-version-update {
        margin-bottom: 40px;
    } .loading {
        top: 400px;
        align-self: center;
    } 
}

@media only screen and (min-width: 720px){
    .active-class {
        margin-left: 60px;
    } .not-active-class {
        margin-left: 60px;
    } .treatment-container {
        width: 600px;
    } .loading {
        top: 400px;
        align-self: center;
    } .cookie > p { 
        margin-left: 60px;
    }  .cookie-accept-btn {
        margin-right: 60px;
    } 
}

@media only screen and (max-width: 860px) {
    .header--links {
        display: none;
    } .info-links {
        flex-direction: column;
        align-items: flex-start;
        font-family: Arial, Helvetica, sans-serif;
    } .info-links button {
        font-family: Arial, Helvetica, sans-serif;
    } .info-links :last-child {
        margin-bottom: 25px;
    }  .info-links > * {
        margin-bottom: 12px;
    } .info-line {
        display: none;
    } .info-container > h2 {
        margin-bottom: 45px;
    } .info-active {
        font-family: Arial, Helvetica, sans-serif;
        font-weight: bold;
    } .info-container h3 {
        margin-bottom: 30px;
        margin-top: 25px;
        font-family: Arial, Helvetica, sans-serif;
    } .loading {
        top: 400px;
    } .text-container {
        padding: 30px;
    } .penicillin-info-image2 {
        width: 100%;
        padding-left: 0;
    } .text-container h2 {
        padding-top: 10px;
    } .penicillin-info {
        flex-direction: column;
        padding-left: 8px;
        padding-right: 8px;
        padding-top: 10px;
        font-size: 44px;
    } .penicillin-info-btn a {
        margin-right: 0;
        margin-bottom: 10px;
    }
}

@media only screen and (min-width: 861px) {
    .mobile-container {
        display: none;
    }
}

@media only screen and (max-width: 1200px) {
    .main-centered {
        background-color: white;
    } .info-container {
        width: 100%;
    } .text-container {
        width: 100%;
    } .modal img {
        max-width: 95vw;
        z-index: 10;
    } .modal {
        max-width: 100%;
        max-height: 100vh; 
        z-index: 10; 
    } 
}

@media only screen and (min-width: 1500px) {
    main {
        display: grid;
        align-items: start;
        /*max-width: 1550px; */
        margin-right: 0px;
        padding-left: 150px;
    } .diagnosis-menu {
        margin-left: 0px;
    } .mobile-container {
        display: none;
    } aside {
        background-color: #FBFBFB;
        grid-column-start: 2;
        grid-row-start: 1;
        justify-self: flex-end;
        max-width: 27vw;
        min-width: 520px;
    } .antibiotics {
        max-width: 680px;
    }  .treatment-container {
        width: 100%;
    } .info-container {
        width: 60%;
    } .recipe-container {
        width: 100%;
    } .strepto-info{
        max-width: 500px;
    } .info-description {
        width: 100%;
    }  .aside-update, .aside-version-update {
        text-align: end;
        margin-right: 40px;
    } .header--links {
        margin-right: 90px;
    } .diagnosis-form {
        justify-content: center;
        margin-top: 18px;
    } .aside-text {
        margin-top: 86px;
    } .loading {
        top: 400px;
        left: 340px;
    } .penicillin-info > p {
        padding-left: 20px;
    } .penicillin-info-btn a {
        margin-right: 15vw;
    } .penicillin-info {
        height: 150px;
    } .text-container {
        width: 60%;
    }
    
}

.loading-container {
    display: flex;
    flex-direction: column;
    align-items: center;
}
  
.loading-animation {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    border: 5px solid #ccc;
    border-top-color: #395174;
    animation: spin 1s ease-in-out infinite;
}
  
.loading-animation-calculations {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    border: 5px solid #ccc;
    border-top-color: #395174;
    animation: spin 1s ease-in-out infinite;
}
  
  @keyframes spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }



